@import url("https://fonts.googleapis.com/css2?family=Anta&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anta&family=Noto+Sans+JP:wght@100..900&display=swap");
.logo {
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-size: 50px;
  color: #021127;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.navbar1 {
  position: fixed;
  width: 100%;
}
.navbar {
  background-color: white;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: 100;
  transition: top 0.6s ease, background-color 0.3s ease;
}
.logo {
  font-family: "Anta", sans-serif;
  font-weight: 400;
  text-decoration: none;
  margin: 30px;
  cursor: pointer;
  font-size: 35px;
  color: #000000;
}
.navbar--items {
  margin-right: 40px;
  display: flex;
  align-items: right;
}

.navbar--items ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.navbar--items ul {
  margin-left: auto;
}
.navbar--content {
  margin-left: 20px;
  text-decoration: none;
  color: #0211277c;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.navbar--active-content {
  color: #0c70d4;
}
.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}
.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}
@media screen and (max-width: 1200px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    background-color: white;
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }

  .navbar--items ul {
    flex-direction: column;
    width: 100%;
    padding: 30px;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: right;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}
.hero--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 100.333px;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
}
.hero--section--content--box > button {
  margin-top: 21.333px;
}
.hero--section--title1 {
  color: rgba(5, 5, 5, 0.52);
  font-size: 27px;
  font-weight: 400;

  align-self: stretch;
}
.section--title {
  display: block;
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
}
.linkWeb {
  text-decoration: None;
  cursor: pointer;
}
.hero--section--content-box {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}
.hero--section--title,
.section--title {
  line-height: 0.8;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
.hero--section-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}
.hero--section--img {
  display: flex;
}
.hero--section--img > img {
  width: 100%;
  height: 100%;
}

.portfolio--section {
  display: flex;
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}
.portfolio--container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.portfolio--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}
.portfolio--section--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42.6px;
  width: 100%;
}
.portfolio--section--img {
  border-radius: 8px;
  width: 100%;
}
.portfolio--section--img > img {
  width: 100%;
}
.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.portfolio--section--title {
  color: var(--heading-color);
}
.portfolio--link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}
.portfolio--section--card:hover path {
  stroke: #006b6a;
}

.testimonial--section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 80px; */

  margin-bottom: 10%;
}
.sections--heading {
  color: var(--heading-color);
}
.testimonial--section--card {
  display: flex;
  padding: 42.6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 42.6px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1.33px solid #006b692e;
}
.testimonial--section--card--reviews {
  display: flex;
  align-items: flex-start;
  gap: 5.33px;
}
.testimonial--section--card--author--detail {
  display: flex;
  align-items: center;
  gap: 21.3px;
}
.testimonial--author--name {
  font-weight: 600;
  color: var(--heading-color);
}
.testimonial--author--designation {
  color: var(--darkblue);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  border: 1.333px solid peru;
}
.btn {
  padding: 40px;
  width: 30%;
}
.footer--container {
  display: flex;
  background-color: #0c70d413;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  align-self: stretch;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

@media only screen and (max-width: 1100px) {
  .skills--section--container {
    gap: 16px;
  }
  .skills--section--card {
    gap: 28px;
  }
  .skills--section--card--content {
    gap: 20px;
  }
  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 980px) {
  .section--title {
    font-size: 50px;
    line-height: 49px;
  }
  .hero--section--title1 {
    font-size: 20px;
  }
  .btn-outline-primary {
    display: none;
  }
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .about-me-heading {
    margin-top: 0px;
  }
  .hero--section--title,
  .hero--section--title1,
  .hero--section-description,
  .footer--content,
  .section--title {
    text-align: center;
  }
  .skills--section--container,
  .about--section,
  .portfolio--section--container,
  .portfolio--container-box,
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  .skills--section,
  .portfolio--container,
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .portfolio--container-box {
    gap: 30px;
  }
  .skills--section,
  .testimonial--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .about--section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .testimonial--section--card {
    padding: 25px;
  }
  .hero--section--title,
  .skills--section--heading,
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
  }
  .hero--section--content--box {
    gap: 10px;
  }
  .contact--form--container {
    width: 100%;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }
}
#AboutMe {
  padding-top: 30px;
  margin-top: 10%;
}
.portfolio-card-1 {
  display: flex;
}
.education-section {
  padding: 5px;
  margin: 10% 0;
}
#Education {
  padding-top: 10%;
}

.education-box {
  margin: 10px;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.208);
  transition: box-shadow 0.6s ease-in-out;
}
.education-box1 {
  padding: 30px;
  border-radius: 8px;

  box-shadow: 0 0px 8px #6490d1;
  transition: box-shadow 0.6s ease-in-out;
}
.education-box:hover {
  box-shadow: 0 8px 16px #6490d1; /* Adjust the box shadow on hover */
}
.education-desc {
  font-size: larger;
}
.col-12 {
  width: 100%;
}
.card-img-top2 {
  margin-top: 1cm;
  max-width: 100%;
  max-height: 200px;
  height: auto;
}

.card-img-top3 {
  margin-top: 1cm;
  max-width: 100%;
  max-height: 200px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}
.card-img-top5 {
  /* margin-top: 1cm; */
  width: 30%;
  /* max-height: 200px; */
  /* height: auto;s */
  border-radius: 10px;
  margin-bottom: 10px;
}
.education-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.skills-content-main {
  text-align: center;
  margin-left: 30px;
}

.center-line {
  width: 50%;
  height: 2px;
  background-color: #00000053;
  margin: 20px auto;
}

.skills-table {
  display: flex;
  flex-wrap: wrap;
}

.skills-cell {
  flex: 1;
  padding: 5px;
  border: 1px solid #1e1c1c66;
  border-radius: 20px;
  margin: 5px;
  text-align: center;
}

.card-body1 {
  margin: 10px;
  padding: 25px;
  height: auto;
  border-radius: 10px;

  transition: box-shadow 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
}

.card-body1 :hover {
  box-shadow: 0 8px 16px rgb(209, 77, 0); /* Adjust the box shadow on hover */
}
.card2 {
  padding: 15px;
  border-radius: 10px;

  box-shadow: 4px 5px 200px rgba(0, 0, 0, 0.1);
}
.card-body2 {
  margin: 15px;
  padding: 10px;
  height: auto;
  border-radius: 10px;
  box-shadow: 4px 5px 200px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.skills-desc {
  padding: 20px;
}

.my-desc {
  font-size: 30px;
}
.interests {
  padding: 30px;
}
.interests a {
  text-decoration: None;
  color: black;
}
.interests ul {
  color: rgb(54, 53, 53);
  font-size: large;
}
.portfolio-container {
  padding: 30px;
  margin: 5% 0;
}
.card-img-top1 {
  width: 100%;
  border: white 2px solid;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
}

.card-img-top4 {
  max-width: 100%;
  max-height: 300px;

  border-radius: 10px;
  /* overflow: hidden; */
}
/* Custom styling for the list */
.card-body2 ul {
  list-style-type: none;
  padding: 0;
}

.card-body2 ul li {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.card-body2 ul li:before {
  content: "\2022"; /* Unicode character for a bullet point */
  position: absolute;
  left: 0;
}
.card-text1 {
  padding: 20px;
  font-size: medium;
  color: rgb(110, 107, 107);
}
@media only screen and (max-width: 1100px) {
  .my-desc {
    font-size: large;
  }
}

.text-gray {
  color: rgba(128, 128, 128, 0.44);
}
@media only screen and (min-width: 985px) {
  .about-me-heading {
    margin-top: 200px;
  }
}
